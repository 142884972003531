import { WWalletIcon, CartIcon, TravelIcon } from "../../../assets/svg";
import { PyButton, Seo } from "../../../components";
import { WebsiteBaseLayout } from "../components/Layout/Base";
import HeroImg from "../../../assets/images/checkout-hero.svg";
import CheckoutFlowIcon from "../../../assets/images/optimized-flows.svg";
import CartValueIcon from "../../../assets/images/increased-cart-value-icon.svg";
import HigherConversionIcon from "../../../assets/images/higher-conversion__.svg";
import MultipleGateways from "../../../assets/images/multiple-gateways_icon.svg";
import HappyWoman from "../../../assets/images/happ-woman.png";
import { urlConfig } from "../../../config";
import { useNavigate } from "react-router-dom";

export const LandingPage = () => {
  const navigate = useNavigate();

  const routeToMerchantWaitlist = () =>
    navigate(`${urlConfig.SITE_LINKS.merchant}/wait-list`);

  return (
    <WebsiteBaseLayout>
      <Seo
        title="Paycurra Checkout | Maximize conversions, minimize cart abandonment,and increase cart value"
        description="Start rewarding customers and accepting card, gift card, stamps or point-based payment options using our web checkout SDK"
        image={`${urlConfig.SITE_LINKS.checkout}/og.png`}
        keywords="Rewards platform, commerce, frontier markets, Shopping Experience, Paycurra Rewards, emerging markets, Paycurra storefront, Customer relationships, Boost sales, Personalized offerings, Customer interactions, Data analysis, Checkout solution, Payment orchestrations, Payment gateway integration, Personalized commerce, Promotional campaigns, Loyalty capabilities, Track campaigns, Ecommerce, Retail, Banks, Fintechs, Travel, Hospitality, Download Paycurra App, Discounts, cashbacks, free gifts, loyalty points"
      />
      <header className="h-full !py-16 bg-white lg:h-[600px]">
        <div className="container flex h-full flex-wrap !px-5 md:!px-11 lg:!px-16">
          <div className="w-full flex items-center mb-12 lg:mb-0 lg:w-1/2">
            <div>
              <h2 className="text-py_black-950 text-[1.75rem] md:text-[2rem] font-semibold leading-[120%] tracking-[-4%]">
                Maximize conversions, <br /> minimize cart abandonment, <br />
                and increase cart value
              </h2>
              <p className="text-py_black-600 text-[0.875rem] md:text-[1em]  font-normal leading-[120%] my-6 lg:mr-30">
                Start rewarding customers and accepting card, gift card, stamps
                or point-based payment options using our web checkout SDK
              </p>

              <div className="flex !gap-5">
                <PyButton
                  click={() => {
                    window.location.href = `${urlConfig.SITE_LINKS.merchant}/wait-list`;
                  }}>
                  Try Checkout SDK
                </PyButton>

                <PyButton
                  extraClass="!border-0"
                  variant="Secondary"
                  click={() => {
                    window.location.href = `${urlConfig.SITE_LINKS.merchant}/wait-list`;
                  }}>
                  Book a demo
                </PyButton>
              </div>
            </div>
          </div>
          <div className="w-full flex items-center md:justify-center lg:w-1/2 justify-end">
            <img
              src={HeroImg}
              width={590}
              height={360}
              className="rounded-4 object-cover"
              alt="Maximize conversions, minimize cart abandonment, and increase
              cart value"
            />
          </div>
        </div>
      </header>

      <main>
        <section id="features" className="!py-20 bg-white">
          <div className="container !px-5 md:px-11 lg:!px-16">
            <div className="w-full mx-auto text-center mb-14 lg:w-[80%]">
              <h4 className="text-py_black-950 text-[1.75rem]  md:text-[2rem] font-semibold lg:leading-[44px] md:leading-[38.4px] leading-[24.2px] tracking-[-2%] !mb-5 xl:w-9/12 xl:mx-auto">
                A seamless checkout solution built to enable accelerated
                business growth
              </h4>
              <p className="text-py_black-700 text-[0.875rem] font-normal leading-[120%] lg:text-[1rem] xl:w-9/12 xl:mx-auto">
                Checkout solution integrated with your promotions and loyalty
                offerings to incentivise your customers and provide more ways to
                pay
              </p>
            </div>

            <div className="flex items-center flex-col lg:flex-row gap-[24px] md:gap-[50px] lg:gap-[110px]">
              <div className="grid grid-cols-2 place-content-center !gap-2 md:!gap-5 w-full lg:w-[calc(50%-55px)]">
                <div className="w-full h-[160px] flex justify-center items-center border border-py_black-200 rounded-2xl p-6">
                  <div className="">
                    <img
                      src={CheckoutFlowIcon}
                      alt="Optimised checkout flow"
                      className="mb-4 md:6 mx-auto w-8 md:w-12 h-8 md:h-12"
                    />

                    <h6 className="text-[0.875rem] text-py_black-950 mb-0 font-medium text-center md:text-[1rem]">
                      Optimised checkout flow
                    </h6>
                  </div>
                </div>

                <div className="w-full h-[160px] flex justify-center items-center border border-py_black-200 rounded-2xl p-6">
                  <div className="">
                    <img
                      src={CartValueIcon}
                      alt="Increase cart value"
                      className="mb-4 md:6 mx-auto w-8 md:w-12 h-8 md:h-12"
                    />

                    <h6 className="text-[0.875rem] text-py_black-950 mb-0 font-medium text-center md:text-[1rem]">
                      Increase cart value
                    </h6>
                  </div>
                </div>

                <div className="w-full h-[160px] flex justify-center items-center border border-py_black-200 rounded-2xl p-6">
                  <div className="">
                    <img
                      src={HigherConversionIcon}
                      alt="Higher conversion"
                      className="mb-4 md:6 mx-auto w-8 md:w-12 h-8 md:h-12"
                    />

                    <h6 className="text-[0.875rem] text-py_black-950 mb-0 font-medium text-center md:text-[1rem]">
                      Higher conversion
                    </h6>
                  </div>
                </div>

                <div className="w-full h-[160px] flex justify-center items-center border border-py_black-200 rounded-2xl p-6">
                  <div className="">
                    <img
                      src={MultipleGateways}
                      alt="Multiple payment gateways"
                      className="mb-4 md:6 mx-auto w-8 md:w-12 h-8 md:h-12"
                    />

                    <h6 className="text-[0.875rem] text-py_black-950 mb-0 font-medium text-center md:text-[1rem]">
                      Multiple payment gateways
                    </h6>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-[calc(50%-55px)] h-[430px] border border-py_black-100 rounded-[16px]">
                <img
                  src={HappyWoman}
                  className="rounded-[16px] object-cover h-full w-full"
                  alt="A seamless checkout solution built to enable accelerated
                business growth"
                />
              </div>

              {/* <div className="flex flex-wrap !gap-5 w-full lg:w-[calc(50%-55px)]">
                <div className="h-[210px] flex flex-col justify-between border !border-py_black-950 !py-10 !px-6 w-[calc((100%/2)-(20px/2))] lg:w-[calc((100%/4)-(60px/4))] lg:min-h-[160px] rounded-2xl">
                  <div className="">
                    <img
                      src={CheckoutFlowIcon}
                      alt="Optimised checkout flow"
                      className="mx-auto"
                    />
                  </div>
                  <h6 className="text-[0.875rem] text-py_black-950 mb-0 font-medium text-center md:text-[1rem]">
                    Optimised checkout flow
                  </h6>
                </div>

                <div className="h-[210px] flex flex-col justify-between border !border-py_black-950 !py-10 !px-6 w-[calc((100%/2)-(20px/2))] lg:w-[calc((100%/4)-(60px/4))] lg:min-h-[211px] rounded-2xl">
                  <div className="">
                    <img
                      src={CartValueIcon}
                      alt="Increase cart value"
                      className="mx-auto"
                    />
                  </div>
                  <h6 className="text-[0.875rem] text-py_black-950 mb-0 font-medium text-center md:text-[1rem]">
                    Increase cart value
                  </h6>
                </div>

                <div className="h-[210px] flex flex-col justify-between border !border-py_black-950 !py-10 !px-6 w-[calc((100%/2)-(20px/2))] lg:w-[calc((100%/4)-(60px/4))] lg:min-h-[211px] rounded-2xl">
                  <div className="">
                    <img
                      src={HigherConversionIcon}
                      alt="Higher conversion"
                      className="mx-auto"
                    />
                  </div>
                  <h6 className="text-[0.875rem] text-py_black-950 mb-0 font-medium text-center md:text-[1rem]">
                    Higher conversion
                  </h6>
                </div>

                <div className="h-[210px] flex flex-col justify-between border !border-py_black-950 !py-10 !px-6 w-[calc((100%/2)-(20px/2))] lg:w-[calc((100%/4)-(60px/4))] lg:min-h-[211px] rounded-2xl">
                  <div className="">
                    <img
                      src={MultipleGateways}
                      alt="Multiple payment gateways"
                      className="mx-auto"
                    />
                  </div>
                  <h6 className="text-[0.875rem] text-py_black-950 mb-0 font-medium text-center md:text-[1rem]">
                    Multiple payment gateways
                  </h6>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        <section className="bg-py_black-950 py-20" id="features">
          <div className="container !px-5 md:!px-11 lg:!px-16">
            <div className="w-full mx-auto text-center mb-14 lg:w-[70%]">
              <h4 className="text-py_black-100 text-[1.5rem] md:text-[2rem] font-semibold leading-[120%] tracking-[-2%] mb-6 xl:w-9/12 xl:mx-auto">
                Build customer engagement experiences that are tailored to your
                industry
              </h4>
              <p className="text-white text-[0.875rem] font-normal leading-[140%] xl:w-10/12 xl:mx-auto">
                Whether you are a fintech insurgent, an incumbent bank or a
                retail store, integrate with the Paycurra Commerce Platform
                today for solutions to help you attract and retain customers
                with personalised offerings.
              </p>
            </div>

            <div className="flex flex-wrap !gap-5">
              <div className="w-full rounded-[1rem] bg-primary-100 py-5 px-6 lg:w-[calc((100%/3)-(40px/3))]">
                <div className="w-[40px] h-[40px] mb-6 rounded-[0.5rem] inline-flex justify-center items-center bg-primary-300">
                  <CartIcon />
                </div>

                <div className=""></div>
                <h6 className="text-[1.125rem] text-py_black-950 mb-3 font-medium">
                  Ecommerce and Retail
                </h6>
                <p className="text-py_black-700 text-[0.875rem] leading-[120%]">
                  Give rewards on first visit and on birthdays, incentivise
                  based on cart attributes, increase order value with realtime
                  recommendations and use gamification to drive repeat
                  behaviours.
                </p>
              </div>

              <div className="w-full rounded-[1rem] bg-py_green_2-100 py-5 px-6 lg:w-[calc((100%/3)-(40px/3))]">
                <div className="w-[40px] h-[40px] mb-6 rounded-[0.5rem] inline-flex justify-center items-center bg-py_green_2-300">
                  <WWalletIcon />
                </div>
                <div className=""></div>
                <h6 className="text-[1.125rem] text-py_black-950 mb-3 font-medium">
                  Banks and Fintechs
                </h6>
                <p className="text-py_black-700 text-[0.875rem] leading-[120%]">
                  Award customers points based on account balance, transaction
                  attributes, or card usage. And create winning Cashback loyalty
                  programmes to incentivise targeted payment options (e.g.
                  BNPL).
                </p>
              </div>

              <div className="w-full rounded-[1rem] bg-py_blue-100 py-5 px-6  lg:w-[calc((100%/3)-(40px/3))]">
                <div className="w-[40px] h-[40px] mb-6 rounded-[0.5rem] inline-flex justify-center items-center bg-py_blue-300">
                  <TravelIcon />
                </div>

                <div className=""></div>
                <h6 className="text-[1.125rem] text-py_black-950 mb-3 font-medium">
                  Travel and Hospitality
                </h6>
                <p className="text-py_black-700 text-[0.875rem] leading-[120%]">
                  Increase repeat business with a tiered system targeting
                  diverse customer segments. Build rules offering free hotel
                  stays or collaborate with partners in travel industry for
                  flight class upgrades.
                </p>
              </div>
            </div>

            <div className="flex justify-center !gap-5 mt-14">
              <PyButton
                click={() => {
                  window.location.href = `${urlConfig.SITE_LINKS.merchant}/wait-list`;
                }}>
                Get started
              </PyButton>
              <PyButton
                extraClass="!text-white !border-white"
                variant="Secondary"
                click={() => {
                  window.location.href = `${urlConfig.SITE_LINKS.merchant}/book-a-demo`;
                }}>
                Book a demo
              </PyButton>
            </div>
          </div>
        </section>
      </main>
    </WebsiteBaseLayout>
  );
};
