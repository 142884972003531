const env = process.env;

const dev = {
  BASE_URL: env.REACT_APP_BASE_URL,
  SUBSCRIPTION_KEY: env.REACT_APP_SUBSCRIPTION_KEY,
  API_TENANT: env.REACT_APP_API_TENANT,
  B2C_CLIENT_ID: env.REACT_APP_B2C_CLIENT_ID,
  B2C_AUTHORITY: env.REACT_APP_B2C_AUTHORITY,
  B2C_KNOW_AUTHORITIES: [env.REACT_APP_B2C_KNOW_AUTHORITIES],
  B2C_REDIRECT_URI: env.REACT_APP_B2C_REDIRECT_URI,
  B2C_POST_LOGOUT_REDIRECT_URI: env.REACT_APP_B2C_POST_LOGOUT_REDIRECT_URI,
  B2C_SCOPES: env.REACT_APP_B2C_SCOPES,
  B2C_SIGN_UP_IN_AUTHORITY: env.REACT_APP_B2C_SIGN_UP_IN_AUTHORITY,
  B2C_FORGOT_PASSWORD_AUTHORITY: env.REACT_APP_B2C_FORGOT_PASSWORD_AUTHORITY,
  B2C_EDIT_PROFILE_AUTHORITY: env.REACT_APP_B2C_EDIT_PROFILE_AUTHORITY,
  B2C_AUTHORITY_DOMAIN: env.REACT_APP_B2C_AUTHORITY_DOMAIN,
  SITE_LINKS: {
    merchant: "https://localhost:3000",
    marketplace: "https://localhost:2401",
    checkout: "/"
  },
  PAYSTACK_SECRET_KEY: process.env.REACT_APP_PAYSTACK,

  PAYCURRA_ADMIN_URN: "51763dbddf8950ff84219582bd77203c",

  // Waiting list
  WAITING_LIST_ILLUSTRATION_1:
    "https://clbsinteuwpycinfrablob.blob.core.windows.net/b2ccustomuicontent/Paycurra/B2CUICustomization/Images/paycurra-campaign-planning.png",
  WAITING_LIST_ILLUSTRATION_2:
    "https://clbsinteuwpycinfrablob.blob.core.windows.net/b2ccustomuicontent/Paycurra/B2CUICustomization/Images/paycurra-wallet.png",
  WAITING_LIST_ILLUSTRATION_3:
    "https://clbsinteuwpycinfrablob.blob.core.windows.net/b2ccustomuicontent/Paycurra/B2CUICustomization/Images/paycurra-shopfront.png"
};

const prod = {
  BASE_URL: "https://clbs-int-euw-pyc-apim.azure-api.net",
  SUBSCRIPTION_KEY: "cefe4c2793614c678d5b8057885459a1",
  API_TENANT: env.REACT_APP_API_TENANT,
  B2C_CLIENT_ID: "dd69d631-7f38-42ea-aeda-5564ba46274a",
  B2C_AUTHORITY:
    "https://auth-int.paycurra.com/4f3bf0bc-9842-477b-96b3-081435a1a7db/B2C_1A_PYC_CONSMR_CHKO_SIGNIN",
  B2C_KNOW_AUTHORITIES: ["auth-int.paycurra.com"],
  B2C_REDIRECT_URI: "https://checkout-int.paycurra.com/loginComplete",
  B2C_POST_LOGOUT_REDIRECT_URI:
    "https://checkout-int.paycurra.com/logout",
  B2C_SCOPES:
    "https://clbsint01euwpyccnsmr.onmicrosoft.com/api://identityexperienceframework/user_impersonation openid",
  B2C_SIGN_UP_IN_AUTHORITY:
    "https://auth-int.paycurra.com/4f3bf0bc-9842-477b-96b3-081435a1a7db/B2C_1A_PYC_CONSMR_SIGNUP_SIGNIN",
  B2C_FORGOT_PASSWORD_AUTHORITY:
    "https://auth-int.paycurra.com/4f3bf0bc-9842-477b-96b3-081435a1a7db/B2C_1A_PYC_CONSMR_PASSWORDRESET",
  B2C_EDIT_PROFILE_AUTHORITY:
    "https://auth-int.paycurra.com/4f3bf0bc-9842-477b-96b3-081435a1a7db/B2C_1A_PYC_CONSMR_PROFILEEDIT",
  B2C_AUTHORITY_DOMAIN: "auth-int.paycurra.com",
  SITE_LINKS: {
    merchant: "https://merchant-int.paycurra.com",
    marketplace: "https://marketplace-int.paycurra.com",
    checkout: "https://checkout-int.paycurra.com"
  },
  PAYSTACK_SECRET_KEY: process.env.REACT_APP_PAYSTACK,

  PAYCURRA_ADMIN_URN: "51763dbddf8950ff84219582bd77203c",

  // Waiting list
  WAITING_LIST_ILLUSTRATION_1:
    "https://assets-int.paycurra.com/b2ccustomuicontent/Paycurra/B2CUICustomization/Images/paycurra-campaign-planning.png",
  WAITING_LIST_ILLUSTRATION_2:
    "https://assets-int.paycurra.com/b2ccustomuicontent/Paycurra/B2CUICustomization/Images/paycurra-wallet.png",
  WAITING_LIST_ILLUSTRATION_3:
    "https://assets-int.paycurra.com/b2ccustomuicontent/Paycurra/B2CUICustomization/Images/paycurra-shopfront.png"
};

export const urlConfig = env.NODE_ENV === "development" ? dev : prod;
