import { WebsiteBaseLayout } from "../components/Layout/Base";
import { PyButton, Seo } from "../../../components";
import {
  WWalletIcon,
  CartIcon,
  TravelIcon,
  WalletLineIcon,
  HeartLineIcon,
  MegaPhoneLineIcon,
  ShoppingCartLineIcon
} from "../../../assets/svg";
import PricingImg1 from "../../../assets/images/pricing-img-1.svg";
import PricingImg2 from "../../../assets/images/pricing-img-2.svg";
import PricingImg3 from "../../../assets/images/pricing-img-3.svg";
import PricingImg4 from "../../../assets/images/pricing-img-4.svg";
import OrangeMask from "../../../assets/images/orange-mask-bg.svg";
import TealMask1 from "../../../assets/images/teal-mask-l-bg.svg";
import TealMask2 from "../../../assets/images/teal-mask-r-bg.svg";
import BlueMask from "../../../assets/images/blue-mask.svg";

import { urlConfig } from "../../../config";
import { useNavigate } from "react-router-dom";

export const Pricing = () => {
  const navigate = useNavigate();
  return (
    <WebsiteBaseLayout>
      <Seo
        title="Payurra | Pricing for merchants both big and small"
        description="Pay-as-you-go, zero setup or hidden fees."
        image={`${urlConfig.SITE_LINKS.checkout}/og.png`}
        keywords="Paycurra, Rewards Infrastructure, Tokenized Payments, Customer Incentives, Payment Experience, Merchant Campaigns, Customer Loyalty, Checkout Rewards, Personalised Commerce, Data-Driven Engagements, Customer Segmentation, Promotional Campaigns, Loyalty Programs, Engagement Metrics, Payment Gateway Integration, Easy Checkout Solutions, Customer Engagement, Ecommerce Rewards, Retail Promotions, Fintech Loyalty Programs, Travel and Hospitality Incentives, Cashback Programs, Gamification, Customer Retention, Payment Orchestrations, Customer Insights, Custom Attributes, Buying Habits, Personalised Offerings, Scalable Marketing Campaigns, Business Growth, Merchant, Checkout, Paycurra marketplace"
      />
      <header className="h-full py-14 border-b border-py_black-100 bg-white lg:py-20">
        <div className="container flex h-full flex-wrap px-5 md:px-10 lg:px-16">
          <div className="w-full flex items-center justify-center mb12">
            <div className="text-center mx-auto lg:w-7/12">
              <h2 className="text-py_black-950 font-semibold text-[2rem] leading-[33px] md:leading-[57.6px] tracking-[-2%] md:tracking:[-4%] mb-5 md:text-[3rem]">
                Clear and simple pricing for Merchants big and small
              </h2>
              <p className="mb-12 text-py_black-600 font-normal lg:text-[1.25rem] leading-[22.4px] md:leading-[24px]">
                Pay-as-you-go, zero setup or hidden fees.
              </p>
              <PyButton
                click={() => {
                  window.location.href = `${urlConfig.SITE_LINKS.merchant}/wait-list`;
                }}>
                Contact Sales
              </PyButton>
            </div>
          </div>
        </div>
      </header>

      <main>
        <section
          className="bg-white relative [backgroundPosition:top_right,left_30%,right_60%,bottom_left] bg-no-repeat py-20"
          style={{
            backgroundImage: `url(${OrangeMask}),url(${TealMask1}),url(${TealMask2}),url(${BlueMask})`
          }}>
          <div className="container px-5 md:px-11 lg:px-16">
            <div className="text-center mb-20 mx-auto lg:w-7/12">
              <h3 className="text-py_black-950 font-semibold text-[22px] md:text-[32px] lg:text-[40px]">
                What you get
              </h3>
              <p className="text-py_black-600 font-normal">
                Get a bundle of out-of-the box features to get you started on
                your growth journey.
              </p>
            </div>
            <div className="flex flex-col gap-20">
              <div className="flex flex-wrap gap-20">
                <div className="w-full flex items-center !order-2 lg:w-[calc((100%/2)-(80px/2))] lg:!order-1">
                  <div className="lg:mr-10">
                    <div className="flex !flex-row lg:!flex-col gap-4 my-5">
                      <div className="w-10 h-10 md:w-14 md:h-14 rounded-[8px] bg-primary-100 flex-shrink-0 flex justify-center items-center">
                        <WalletLineIcon className="w-6 h-6 md:w-8 md:h-8" />
                      </div>
                      <h4 className="text-[18px] md:text-[22px] lg:text-[28px] text-py_black-950 font-semibold leading-[21.6px] md:leading-[26.4px] lg:leading-[33.6px] tracking-[-2%]">
                        Delight your customers with more ways to shop and pay at
                        checkout
                      </h4>
                    </div>

                    <ul className="flex flex-col !gap-4">
                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Bring your own payment gateway
                      </li>

                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Pay with points, coupons, cashbacks and cards
                      </li>

                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Preview of checkout earnings to incentivise conversion
                        and grow cart value
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="w-full flex items-center justify-center !order-1 lg:w-[calc((100%/2)-(80px/2))] justify-end lg:!order-2">
                  <img
                    src={PricingImg1}
                    alt=" Boost your sales with personalised offerings built from rich
                    profiles and custom attributes"
                    width={470}
                    height={270}
                    className="w-full md:w-[400px] xl:w-[470px]"
                  />
                </div>
              </div>

              <div className="flex flex-wrap gap-20">
                <div className="w-full flex items-center justify-center lg:w-[calc((100%/2)-(80px/2))]">
                  <img
                    src={PricingImg2}
                    alt="Transform casual shoppers into loyal customers by using
                  our promotional campaigns and loyalty capabilities."
                    width={470}
                    height={270}
                    className="w-full md:w-[400px] xl:w-[470px]"
                  />
                </div>

                <div className="w-full !order-1 lg:w-[calc((100%/2)-(80px/2))] lg:!order-1">
                  <div className="lg:ml-10">
                    <div className="flex !flex-row lg:!flex-col gap-4 my-5">
                      <div className="w-10 h-10 md:w-14 md:h-14 rounded-[8px] bg-py_green_2-100 flex-shrink-0 flex justify-center items-center">
                        <HeartLineIcon className="w-6 h-6 md:w-8 md:h-8" />
                      </div>
                      <h4 className="text-[18px] md:text-[22px] lg:text-[28px] text-py_black-950 font-semibold leading-[21.6px] md:leading-[26.4px] lg:leading-[33.6px] tracking-[-2%]">
                        Create and manage a winning loyalty programmes
                      </h4>
                    </div>

                    <ul className="flex flex-col !gap-4">
                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Coalition programmes
                      </li>

                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Multi-tiered and paid membership programmes
                      </li>

                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Publishing of loyalty membership programmes to the
                        Paycurra mobile wallet app
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap gap-20">
                <div className="w-full !order-2 lg:w-[calc((100%/2)-(80px/2))] lg:!order-1">
                  <div className="lg:mr-10">
                    <div className="flex !flex-row lg:!flex-col gap-4 my-5">
                      <div className="w-10 h-10 md:w-14 md:h-14 rounded-[8px] bg-py_green_1-100 flex-shrink-0 flex justify-center items-center">
                        <MegaPhoneLineIcon className="w-6 h-6 md:w-8 md:h-8" />
                      </div>
                      <h4 className="text-[18px] md:text-[22px] lg:text-[28px] text-py_black-950 font-semibold leading-[21.6px] md:leading-[26.4px] lg:leading-[33.6px] tracking-[-2%]">
                        Create and plan effective promotional campaigns
                      </h4>
                    </div>

                    <ul className="flex flex-col !gap-4">
                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Flexible rules engine
                      </li>
                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Multiple coupon types: discount, stamps, free gifts,
                        cash back
                      </li>
                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Usage tracking and monitoring
                      </li>
                      <li className="text-[0.875rem] text-py_black-700 font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Publishing of promotion-linked rewards to the Paycurra
                        mobile wallet app
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="w-full flex items-center justify-center !order-1 lg:w-[calc((100%/2)-(80px/2))] justify-end lg:!order-2">
                  <img
                    src={PricingImg3}
                    alt="Integrate your existing payment gateway accounts:
                  Paystack, Stripe, and more to your Paycurra wallet."
                    width={470}
                    height={270}
                    className="w-full md:w-[400px] xl:w-[470px]"
                  />
                </div>
              </div>

              <div className="flex flex-wrap gap-20">
                <div className="w-full flex items-center justify-center lg:w-[calc((100%/2)-(80px/2))]">
                  <img
                    src={PricingImg4}
                    alt="Transform casual shoppers into loyal customers by using
                  our promotional campaigns and loyalty capabilities."
                    width={470}
                    height={270}
                    className="w-full md:w-[400px] xl:w-[470px]"
                  />
                </div>

                <div className="w-full order-1 lg:w-[calc((100%/2)-(80px/2))] lg:order-1">
                  <div className="lg:ml-10">
                    <div className="flex !flex-row lg:!flex-col gap-4 my-5">
                      <div className="w-10 h-10 md:w-14 md:h-14 rounded-[8px] bg-py_blue-100 flex-shrink-0 flex justify-center items-center">
                        <ShoppingCartLineIcon className="w-6 h-6 md:w-8 md:h-8" />
                      </div>
                      <h4 className="text-[18px] md:text-[22px] lg:text-[28px] text-py_black-950 font-semibold leading-[21.6px] md:leading-[26.4px] lg:leading-[33.6px] tracking-[-2%]">
                        Gain insight into customer behaviour and manage
                        engagements{" "}
                      </h4>
                    </div>

                    <ul className="flex flex-col !gap-4">
                      <li className="text-[0.875rem] text-py_black-950 leading-[19.6px] font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Measure and track customer engagement KPIs
                      </li>
                      <li className="text-[0.875rem] text-py_black-950 leading-[19.6px] font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Review consumer checkout sessions and manage disputes
                      </li>
                      <li className="text-[0.875rem] text-py_black-950 leading-[19.6px] font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Personalise rewards based on customer insights
                      </li>
                      <li className="text-[0.875rem] text-py_black-950 leading-[19.6px] font-normal border border-py_black-100 rounded-[1rem] !py-5 px-6">
                        Recover abandoned carts with personalised emails and
                        linked coupon offerings tailored to convert
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-py_black-950 py-20">
          <div className="container !px-5 md:!px-11 lg:!px-16">
            <div className="w-full mx-auto text-center mb-14 lg:w-[70%]">
              <h4 className="text-py_black-100 text-[1.5rem] md:text-[22px] lg:text-[2.25rem] font-semibold leading-[26.4px] md:leading-[38.4px] lg:leading-[48px] tracking-[-2%] mb-6 xl:w-9/12 xl:mx-auto">
                Build customer engagement experiences that are tailored to your
                industry
              </h4>
              <p className="text-white text-[0.875rem] md:text-[1rem] font-normal leading-[19.6px] md:leading-[22.4px] xl:w-10/12 xl:mx-auto">
                Whether you are a fintech insurgent, an incumbent bank or a
                retail store, integrate with the Paycurra Commerce Platform
                today for solutions to help you attract and retain customers
                with personalised offerings.
              </p>
            </div>

            <div className="flex flex-wrap !gap-5">
              <div className="w-full rounded-[1rem] bg-primary-100 py-5 px-6 lg:w-[calc((100%/3)-(40px/3))]">
                <div className="w-[40px] h-[40px] mb-6 rounded-[0.5rem] inline-flex justify-center items-center bg-primary-300">
                  <CartIcon />
                </div>

                <h6 className="text-[1.125rem] text-py_black-950 mb-3 font-medium">
                  Ecommerce and Retail
                </h6>
                <p className="text-py_black-600 text-[0.875rem] leading-[120%]">
                  Give rewards on first visit and on birthdays, incentivise
                  based on cart attributes, increase order value with realtime
                  recommendations and use gamification to drive repeat
                  behaviours.
                </p>
              </div>

              <div className="w-full rounded-[1rem] bg-py_green_2-100 py-5 px-6 lg:w-[calc((100%/3)-(40px/3))]">
                <div className="w-[40px] h-[40px] mb-6 rounded-[0.5rem] inline-flex justify-center items-center bg-py_green_2-300">
                  <WWalletIcon />
                </div>
                <h6 className="text-[1.125rem] text-py_black-950 mb-3 font-medium">
                  Banks and Fintechs
                </h6>
                <p className="text-py_black-600 text-[0.875rem] leading-[120%]">
                  Award customers points based on account balance, transaction
                  attributes, or card usage. And create winning Cashback loyalty
                  programmes to incentivise targeted payment options (e.g.
                  BNPL).
                </p>
              </div>

              <div className="w-full rounded-[1rem] bg-py_blue-100 py-5 px-6 lg:w-[calc((100%/3)-(40px/3))]">
                <div className="w-[40px] h-[40px] mb-6 rounded-[0.5rem] inline-flex justify-center items-center bg-py_blue-300">
                  <TravelIcon />
                </div>

                <h6 className="text-[1.125rem] text-py_black-950 mb-3 font-medium">
                  Travel and Hospitality
                </h6>
                <p className="text-py_black-600 text-[0.875rem] leading-[120%]">
                  Increase repeat business with a tiered system targeting
                  diverse customer segments. Build rules offering free hotel
                  stays or collaborate with partners in travel industry for
                  flight class upgrades.
                </p>
              </div>
            </div>

            <div className="flex justify-center !gap-5 mt-14">
              <PyButton
                click={() => {
                  window.location.href = `${urlConfig.SITE_LINKS.merchant}/wait-list`;
                }}>
                Get started
              </PyButton>
              <PyButton
                extraClass="!text-white !border-white"
                variant="Secondary"
                click={() => {
                  window.location.href = `${urlConfig.SITE_LINKS.merchant}/wait-list`;
                }}>
                Book a demo
              </PyButton>
            </div>
          </div>
        </section>
      </main>
    </WebsiteBaseLayout>
  );
};
